<template>
  <div>
    <div class="header">
      <!-- <div slot="title" class="left_top">
        <div class="left_icon">
          <span></span> <span>{{ title }}</span>
        </div>
      </div>-->
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack()" />
        <span class="center">权限管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center" @click="goBack">资源管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ title }}</span>
      </div>
      <el-button type="primary" class="top_btn" size="small" @click="submitForm('ruleForm')">保 存</el-button>
    </div>
    <div class="content">
      <!-- <el-card class="box-card"> -->
      <el-form
        width="900px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="110px"
      >
        <!-- <Divider title=""></Divider> -->
        <el-card class="box-card">
          <div class="left_top" style="margin:10px">
            <div class="left_icon">
              <span></span>
              <span>基础功能</span>
            </div>
          </div>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="功能名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属模块" prop="objectName">
                <el-select
                  style="width: 100%"
                  v-model="ruleForm.objectName"
                  remote
                  reserve-keyword
                  placeholder="请选择所属模块名称"
                  filterable
                  @change="objectNameChange(ruleForm.objectName)"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.objectAlias"
                    :label="item.objectAlias"
                    :value="item.objectAlias"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="功能对应接口" prop="api">
                <el-select
                  style="width: 100%"
                  v-model="ruleForm.api"
                  placeholder="请选择"
                  @change="apiChange(ruleForm.api)"
                >
                  <el-option
                    v-for="(item, index) in apiList"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="控制标识" prop="action">
                <el-input v-model="ruleForm.action"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="依赖权限" prop="desp">
            <el-select
              v-model="ruleForm.desp"
              multiple
              filterable
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in relyList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字段权限" prop="fields">
            <el-select
              v-model="ruleForm.fields"
              multiple
              placeholder="请选择"
              style="width: 100%"
              filterable
              allow-create
              @change="fieldsChange"
            >
              <el-option label="$all" value="$all"></el-option>
              <el-option v-for="item in fieldsList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <div style="display: flex; justify-content: space-between">
            <el-form-item label="是否禁用" prop="enable">
              <el-switch v-model="ruleForm.enable" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
            <el-form-item label="是否可配置" prop="custom">
              <el-switch v-model="ruleForm.custom" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
            <el-form-item label="是否公共组件" prop="common">
              <el-switch v-model="ruleForm.common" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </div>
          <el-form-item label="描述" prop="desc">
            <el-input type="textarea" v-model="ruleForm.desc" maxlength="500" show-word-limit></el-input>
          </el-form-item>
        </el-card>

        <el-card class="box-card">
          <div class="left_top" style="margin:10px">
            <div class="left_icon">
              <span></span>
              <span>数据功能</span>
            </div>
            <div slot="right">
              <el-button type="text" @click="addOne" style="padding: 0;">添加规则</el-button>
            </div>
          </div>
          <el-form-item label="数据权限" prop="scope">
            <ScopeVue
              @childByValue="childByValue"
              :inputName="fieldsList"
              :spoceList="ruleForm.scope"
              :list="list"
            ></ScopeVue>
          </el-form-item>
        </el-card>
      </el-form>
      <!-- </el-card> -->
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { api } from '/src/api/base';
export const roleResourceNameCodes = api()('/roleResource.nameCodes.json');

export const roleResourceAdd = api()('/roleResource.add.json');
export const roleResourceEdit = api()('/roleResource.edit.json');
export const roleResourceBaseObjects = api()('/roleResource.baseObjects.json');
export const roleResourceOne = api()('/roleResource.one.json');
export default {
  components: {
    ScopeVue: () => import('./scope')
  },
  data () {
    return {
      title: '新增资源',
      type: 'add',
      code: '',
      ruleForm: {
        scope: [],
        name: '',
        objectName: '',
        desc: '',
        desp: '',
        api: '',
        action: '',
        fields: '',
        enable: true,
        custom: true,
        common: true
      },
      rules: {
        name: [{ required: true, message: '请输入功能名称', trigger: 'blur' }],
        objectName: [
          { required: false, message: '请选择所属模块', trigger: 'change' }
        ],
        api: [
          { required: false, message: '请选择功能对应接口', trigger: 'change' }
        ],

        scope: [],
        desp: [],
        desc: [],
        type: [],
        action: [
          { required: false, message: '请输入控制标识', trigger: 'blur' }
        ],
        fields: [],
        enable: [],
        custom: [],
        common: [],
        fieldsList: []
      },
      typeList: [],
      apiList: [],
      relyList: [],
      fieldsList: [],
      loading: false,
      oldFields: [],
      list: [1]
    };
  },
  async created () {
    const query = this.$route.query;
    this.type = query.type;
    this.code = query.code;

    this.title =
      query.type === 'add'
        ? '新增资源'
        : query.type === 'edit'
          ? '编辑资源'
          : '复制资源';
    await this.getRoleResourceBaseObjects();
    await this.getRoleResourceList();
    if (this.type === 'copy' || this.type === 'edit') {
      this.getRoleResourceOne();
    }
  },
  methods: {
    async getRoleResourceList () {
      const res = await roleResourceNameCodes({
        pageSize: 1000
      });
      this.relyList = res;
    },
    getRoleResourceBaseObjects () {
      roleResourceBaseObjects().then(res => {
        this.typeList = res;
      });
    },
    objectNameChange (name) {
      const _selectOne = this.typeList.find(it => it.objectAlias === name);
      this.ruleForm.api = '';
      this.ruleForm.action = '';
      this.apiList = _selectOne.configApi;
      this.fieldsList = _selectOne.feilds;
    },
    apiChange (api) {
      this.ruleForm.action = _.camelCase(api.replace('.json', ''));
    },
    fieldsChange () {
      if (
        this.oldFields.indexOf('$all') === -1 &&
        this.ruleForm.fields.indexOf('$all') > -1
      ) {
        this.ruleForm.fields = ['$all'];
      }
      if (
        this.oldFields.indexOf('$all') > -1 &&
        this.ruleForm.fields.indexOf('$all') > -1 &&
        this.ruleForm.fields.length > 1
      ) {
        this.ruleForm.fields = this.ruleForm.fields.filter(it => it !== '$all');
      }
      this.oldFields = _.castArray(this.ruleForm.fields);
    },
    // 获取资源信息
    async getRoleResourceOne () {
      const result = await roleResourceOne({ code: this.code });
      result.fields = result.fields ? result.fields.split(',') : [];
      result.scope = JSON.parse(result.scope);

      console.log(this.typeList);
      if (result.objectName) {
        this.apiList = this.typeList.filter(
          it => it.objectAlias === result.objectName
        )[0].configApi;
        this.fieldsList = this.typeList.filter(
          it => it.objectAlias === result.objectName
        )[0].feilds;
      }
      Object.keys(this.ruleForm).forEach(key => {
        this.ruleForm[key] = result[key];
      });
    },
    childByValue (childByValue) {
      this.ruleForm.scope = childByValue;
    },
    close (form) {
      this.$refs[form].resetFields();
      window.history.back(-1);
    },
    submitForm (form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          const params = {
            ...this.ruleForm,
            scope: JSON.stringify(this.ruleForm.scope),
            fields: this.ruleForm.fields.join(',')
          };
          if (this.type === 'add' || this.type === 'copy') {
            await roleResourceAdd(params);
            this.$message.success(`资源 ${this.ruleForm.name} 添加成功！`);
          } else {
            await roleResourceEdit({
              code: this.code,
              ...params
            });
            this.$message.success(`资源 ${this.code} 编辑成功！`);
          }
          this.close(form);
        }
      });
    },
    addOne () {
      this.list.push(1);
    },
    goBack () {
      window.history.back(-1);
    }
  }
};
</script>

<style>
.content {
  margin: 0 auto;
}
</style>
